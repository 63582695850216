import { BackTop } from "antd";
// import DraggableJivoChatIcon from "components/DraggableJivoChatIcon";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "routes/Router";
import useQuantcast from "useQuantcast";
// import useQuantcast from "../useQuantcast";
function App() {
  useQuantcast();
  return (
    <>
      {/* <DraggableJivoChatIcon /> */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BackTop />
      <AppRouter />
    </>
  );
}

export default App;
